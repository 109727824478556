<template>
  <div>
    <base-page-title :titleText="pageTitle" />
    <b-col class="col-12 col-md-8 p-3 mx-auto">
      <b-card no-body class="p-3 mb-2">
        <validation-observer ref="accountForm" v-slot="{ valid }">
          <b-form>
            <b-row class="no-gutters">
              <b-col class="col-12 col-md-6 pr-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('firstName')"
                  label-for="firstName"
                >
                  <validation-provider
                    :name="$t('firstName')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="firstName"
                      v-model="user.firstName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('lastName')"
                  label-for="lastName"
                >
                  <validation-provider
                    :name="$t('lastName')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="lastName"
                      v-model="user.lastName"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pr-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('email')"
                  label-for="email"
                >
                  <validation-provider
                    v-if="formType === 'NEW'"
                    :name="$t('email')"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="email"
                      v-model="user.email"
                      :state="getValidationState(validationContext)"
                      @input="user.email = user.email.toLowerCase()"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                  <div v-else class="text-muted mt-n2">
                    {{ user.email }}
                  </div>
                </b-form-group>
              </b-col>
              <b-col class="col-12 col-md-6 pl-md-1">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('phone')"
                  label-for="phone"
                >
                  <validation-provider
                    :name="$t('phone')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="phone"
                      v-model="user.phone"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col class="col-12">
                <b-form-group
                  label-class="font-weight-bold"
                  :label="$t('position')"
                  label-for="position"
                >
                  <validation-provider
                    :name="$t('position')"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-input
                      id="position"
                      v-model="user.position"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="mt-3"></div>
            <b-row v-if="formType === 'NEW'" class="no-gutters">
              <b-button class="ml-auto" :disabled="!valid" @click="setUser">
                {{ actionBtnLabel }}
              </b-button>
            </b-row>
            <b-row v-else-if="formType === 'EDIT'" class="no-gutters">
              <b-col class="col-auto">
                <b-button
                  class="mr-1 mb-1"
                  variant="danger"
                  @click="openDeleteConfirmationDialog"
                >
                  {{ $t("button.delete") }}
                </b-button>
              </b-col>
              <b-col class="col-auto ml-auto mr-1 mb-1">
                <b-button variant="outline-primary" @click="cancel">
                  {{ $t("button.cancel") }}
                </b-button>
              </b-col>
              <b-col class="col-auto mr-1 mb-1">
                <b-button variant="outline-primary" @click="passwordRecovery">
                  {{ $t("button.passwordRecovery") }}
                </b-button>
              </b-col>
              <b-col class="col-auto mr-1 mb-1">
                <b-button :disabled="!valid" @click="setUser">
                  {{ actionBtnLabel }}
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card>
    </b-col>
  </div>
</template>

<script>
import Utils from "./../../Utils";
export default {
  props: {
    formType: {
      type: String,
      required: true
    },
    pageTitle: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    actionBtnLabel() {
      return this.formType === "NEW"
        ? this.$t("button.createAccount")
        : this.$t("button.updateAccount");
    },
    countryOptions() {
      return Utils.COUNTRY_LIST;
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, untouched }) {
      // if (!untouched && (dirty || validated)) return valid ? null : valid;
      // else return null;
      if (!untouched) return valid ? null : valid;
      else return null;
    },
    setUser() {
      this.$emit("setUser", this.user);
    },
    openDeleteConfirmationDialog() {
      const employeeFullName =
        this.user.firstName && this.user.lastName
          ? `${this.user.firstName} ${this.user.lastName}`
          : null;
      this.$store.commit("confirm/SET_CONFIRM", {
        titleText: "dialog.confirm.deleteEmployeeTitle",
        messageText: "dialog.confirm.deleteEmployeeMessage",
        messageParams: { employeeFullName },
        confirmBtnCallback: this.deleteUser,
        type: "DELETE"
      });
    },
    deleteUser() {
      this.$emit("deleteUser", this.user._id);
    },
    cancel() {
      this.$emit("cancel");
    },
    passwordRecovery() {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("auth/recoverUserPassword", {
          email: this.user.email
        })
        .then(() => {
          this.$store.commit("success/SET_SUCCESS_INFO", {
            titleText: "dialog.success.employeePasswordRecovery.title",
            messageText: "dialog.success.employeePasswordRecovery.message",
            messageParams: { email: this.user.email }
          });
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    }
  }
};
</script>

<style scoped></style>
