<template>
  <div>
    <user-form
      :formType="formType"
      :pageTitle="pageTitle"
      :user="user"
      @setUser="updateUserAccount"
      @deleteUser="deleteUserAccount"
      @cancel="goToDashboard"
    />
  </div>
</template>

<script>
import UserForm from "./../components/User/UserForm";
export default {
  components: {
    UserForm
  },
  data() {
    return {
      formType: "EDIT",
      pageTitle: "editAccount"
    };
  },
  computed: {
    user() {
      const activeUser = {
        ...this.$store.getters["employees/getActiveEmployee"]
      };
      activeUser.email = activeUser.registration.email;
      return activeUser;
    }
  },
  methods: {
    updateUserAccount(user) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("employees/updateEmployee", user)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    deleteUserAccount(userId) {
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("employees/deleteEmployee", userId)
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    goToDashboard() {
      this.$router.push({ name: "Dashboard" });
    }
  }
};
</script>

<style></style>
